/*----- Custom Control -----*/

.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-control-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-right: 1rem;
}

.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
	&:checked~.custom-control-label::before {
		color: $white;
	}
	&:active~.custom-control-label::before {
		color: $white;
		background-color: #d4e1f4;
	}
	&:disabled~.custom-control-label {
		color: $gray;
		&::before {
			background-color: #d8dde6;
		}
	}
}

.custom-control-label {
	margin-bottom: 0;
	position: static !important;
	vertical-align: middle;
	&::before {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		pointer-events: none;
		content: "";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: #d8dde6;
	}
	&::after {
		position: absolute;
		top: .25rem;
		left: 0;
		display: block;
		width: 1rem;
		height: 1rem;
		content: "";
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}

.custom-checkbox .custom-control-label::before {
	border-radius: 3px;
}

.br.custom-checkbox .custom-control-label::before {
	border-radius: 50px;
}

.custom-checkbox .custom-control-input {
	&:checked~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
	}
	&:indeterminate~.custom-control-label::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
	}
	&:disabled {
		&:checked~.custom-control-label::before,
		&:indeterminate~.custom-control-label::before {
			background-color: rgba(5, 117, 230, 0.5) ;
		}
	}
}

.custom-radio {
	.custom-control-label::before {
		border-radius: 50%;
	}
	.custom-control-input {
		&:checked~.custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
		}
		&:disabled:checked~.custom-control-label::before {
			background-color: rgba(5, 117, 230, 0.5);
		}
	}
}

.form-select {
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	padding: .5rem 1.75rem .5rem .75rem;
	line-height: 1.5;
	color: #a7b4c9;
	vertical-align: middle;
	background: $white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3E%3Cpath fill='#999' d='M0 0L10 0L5 5L0 0'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
	background-size: 8px 10px;
	border: 1px solid #d8dde6;
	border-radius: 3px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: 0;
		box-shadow: none;
		&::-ms-value {
			color: #3d4e67;
			background-color: $white;
		}
	}
	&[multiple],
	&[size]:not([size="1"]) {
		height: auto;
		padding-right: .75rem;
		background-image: none;
	}
	&:disabled {
		color: $gray;
		background-color: #d8dde6;
	}
	&::-ms-expand {
		opacity: 0;
	}
}

.form-select-sm {
	height: calc(1.8125rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 75%;
}

.form-select-lg {
	height: calc(2.6875rem + 2px);
	padding-top: .5rem;
	padding-bottom: .5rem;
	font-size: 125%;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 2.375rem;
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 2.375rem;
	margin: 0;
	opacity: 0;
	&:lang(en)~.custom-file-label::after {
		content: "Browse";
	}
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 2.375rem;
	padding: .375rem .75rem;
	line-height: 1.8;
	color: #3d4e67;
	background-color: $white;
	border: 1px solid #d8dde6;
	border-radius: 3px;
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(2.375rem - 1px * 2);
		padding: .375rem .75rem;
		line-height: 1.5;
		color: $white;
		content: "Browse";
		border-radius: 0 3px 3px 0;
	}
}

.form-range {
	&::-webkit-slider-thumb:active {
		background-color: #d4e1f4;
	}
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: .5rem;
		content: '';
		color: transparent;
		cursor: pointer;
		background-color: #d8dde6;
		border-color: transparent;
		border-radius: 1rem;
		pointer-events: none;
	}
	&::-moz-range-thumb:active {
		background-color: #d4e1f4;
	}
	&::-ms-thumb {
		width: 1rem;
		height: 1rem;
		border: 0;
		border-radius: 1rem;
		appearance: none;
		width: 240px;
		height: 2px;
		background: $white;
		border-radius: 50px;
		border: 1px solid rgba(0, 30, 75, 0.12);
		transition: .3s border-color, .3s background-color;
		&:active {
			background-color: #d4e1f4;
		}
	}
	&::-ms-track {
		width: 100%;
		height: .5rem;
		color: transparent;
		cursor: pointer;
		background-color: transparent;
		border-color: transparent;
		border-width: .5rem;
		border: 0;
		border-radius: 0;
		margin-top: 10px;
		width: 240px;
	}
	&::-ms-fill-lower {
		background-color: #d8dde6;
		border-radius: 1rem;
		background: $blue;
		border-radius: 0;
	}
	&::-ms-fill-upper {
		margin-right: 15px;
		background-color: #d8dde6;
		border-radius: 1rem;
		background: rgba(0, 50, 126, 0.12);
		border-radius: 0;
	}
}

.custom-controls-stacked .custom-control {
	margin-bottom: .25rem;
}

.custom-control-label:before {
	border: 1px solid rgba(167, 180, 201, 0.5);
	background-color: $white;
	background-size: .5rem;
}

.custom-control-description {
	line-height: 1.5rem;
}